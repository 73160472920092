<template>
    <div>
        <top-nav></top-nav>
        <header-guide></header-guide>
        <bread-crumb></bread-crumb>
        <main class="signUp-main">
            <div class="container">
                <div class="success-tips-panel">
                    <span class="icon">
                        <img :src="$img.success_ico" />
                    </span>
                    <div class="success-txt">
                        <h4>报名成功</h4>
                        <p>
                            您已经成功报名了
                            <span>《{{task.task_name}}》</span>项目，雇主会根据报名信息确定合作的工程极客，请耐心等待哦！
                        </p>
                        <el-row>
                            <el-button
                                type="primary"
                                @click="goDetail"
                                class="back-task-detail"
                            >返回项目详情页</el-button>

                            <el-button @click="goIndex">返回首页</el-button>
                        </el-row>
                    </div>
                </div>
            </div>
        </main>
        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import { mapState, mapActions } from "vuex";
export default {
    name: "taskEnrollSuccessTips",
    components: {
        TopNav, // 头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        GlobalFooter //公共底部
    },
    computed: {
        ...mapState(["userInfo"])
    },
    data() {
        return {
            task: {
                task_name: "",
                geeks_credentials: "",
                remuneration: 0.0,
                enroll_times: 0,
                points_surplus: 0,
                surplus: 0,
                task_type: 1,
                end_time: ""
            }
        };
    },
    methods: {
        goDetail() {
            this.$router.push("/task/detail?tk=" + this.$route.query.tk);
        },
        goIndex() {
            this.$router.push("/");
        },
        getTask() {
            var _this = this;
            this.post(
                "/task/index/detail",
                {
                    tk: this.$route.query.tk
                },
                function (res) {
                    if (res.code == 200) {
                        _this.task = res.data;
                        _this.task.geeks_credentials = res.data
                            .geeks_credentials
                            ? res.data.geeks_credentials
                            : "-";
                        res.data.enroll_times > 0
                            ? (_this.enrollTimes = true)
                            : (_this.enrollTimes = false);
                    } else {
                        this.$message.error("项目不存在");
                    }
                }
            );
        }
    },
    created: function () {
        this.getTask();
    }
};
</script>
<style lang="less">
@import "../../styles/signup.less";
.back-task-detail:hover {
    background: #4564b3 !important;
}
</style>



